.app__about_me {
  padding-top: 6rem;
  background: var(--primary-color);
  width: 100vw;
  min-height: calc(100vh - 6rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__about_me-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* This centers the children horizontally */
  width: 100%; /* Ensures the container spans the full width of its parent */
  max-width: 50rem; /* Adjust this value to match the gallery width or as needed */
  margin: auto; /* Centers the container if it's narrower than its parent */
}

.app__about_me-summary {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 3rem;
}

.app__about_me-summary p {
  text-align: justify;
  text-justify: inter-word;
  font-family: var(--font-base);
  font-weight: var(--font-weight-mediumm);
  line-height: 1.6;
}

.app__about_me-amsterdam-gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  width: 100%;
}

.app__about_me-amsterdam-gallery .image-container {
  position: relative;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.app__about_me-amsterdam-gallery img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2%;
}

/* Correcting aspect ratio for non-landscape images */
.app__about_me-amsterdam-gallery .image-container:not(.landscape) img {
  aspect-ratio: 3 / 4;
}

.app__about_me-amsterdam-gallery .landscape {
  grid-column: 1 / -1;
  aspect-ratio: auto;
}

.app__about_me-amsterdam-gallery .image-container:hover .expand-icon {
  opacity: 1;
  transform: scale(2);
}

@media screen and (max-width: 800px) {
  .app__about_me-container {
    max-width: 90%; /* Allow the container to be more responsive to screen size */
    padding: 0 1rem; /* Add padding to ensure content does not touch the screen edges */
  }

  .app__about_me-amsterdam-gallery {
    grid-template-columns: 1fr; /* Adjust gallery to a single column layout */
    padding: 0; /* Remove additional padding if previously added */
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .app__about_me-amsterdam-gallery img {
    aspect-ratio: auto; /* Ensure images maintain their natural aspect ratio */
    margin-bottom: 1rem; /* Add some space between images */
  }

  .app__about_me-amsterdam-gallery .image-container .expand-icon {
    bottom: 1.8rem;
    right: 1rem;
  }
}
