@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,500;0,900;1,100;1,500&display=swap');

:root {
  --font-base: 'Roboto', sans-serif;
  --font-weight-black: 900;
  --font-weight-medium: 500;
  --font-weight-mediumm: 300;
  --font-weight-thin: 100;
  --primary-color: #f5f5f5;
  --accent-color: #005792;
  --tertiary-color: #ff6b6b;
  --text-color: #333333;
  --title-color: #4f4f4f;
  --background-accent: #e1f5fe;
  --white-color: #ffffff;
  --black-color: #000000;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: var(--font-base);
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  min-height: 100%;
  overflow-x: hidden;
  background: linear-gradient(
    90deg,
    var(--primary-color) 30%,
    var(--white-color) 70%
  );
}

ul[role='list'],
ol[role='list'],
li {
  list-style: none;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
svg,
picture {
  max-width: 100%;
  display: block;
}

@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
