.app__home {
  margin-top: 6rem;
  width: 100vw;
  min-height: calc(100vh - 6rem); /* Adjust for navbar height */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-base);
}

.app__home-about_me {
  position: relative;
  display: flex;
}

.app__home-about_me-card {
  background: var(--secondary-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  width: 25rem;
  height: 35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 3%;
  overflow: hidden;
}

.app__home-about_me-card-photo {
  width: 11.5rem;
  height: 12rem;
  overflow: hidden;
  margin-top: 3.5rem;
  border-radius: 50%;
  flex: 4;
}

.app__home-about_me-card-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.app__home-about_me-card-name {
  padding-top: 2rem;
  flex: 2;
}

.app__home-about_me-card-name,
.app__home-about_me-card-title {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: var(--font-base);
}

.app__home-about_me-card-name {
  font-weight: var(--font-weight-black);
  font-size: 2rem;
}

.app__home-about_me-card-title,
.app__home-about_me-card-certification {
  font-family: var(--font-base);
  font-weight: var(--font-weight-medium);
  font-size: 1rem;
}

.app__home-about_me-card-title a,
.app__home-about_me-card-certification a {
  text-decoration: none;
  color: var(--accent-color);
}
.app__home-about_me-card-title a:hover,
.app__home-about_me-card-certification a:hover {
  font-weight: var(--font-weight-black);
}

.app__home-about_me-card-links {
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  display: flex;
  flex: 1;
}

.app__home-about_me-card-links a {
  text-decoration: none;
  font-family: var(--font-base);
  font-weight: var(--font-weight-medium);
}

.app__home-about_me-card-socials {
  width: 100%;
  background: var(--accent-color);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

@media screen and (max-width: 800px) {
  .app__home-about_me-card {
    margin: 5rem 0;
  }
}
