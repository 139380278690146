.app__navbar {
  width: 100%;
  padding: 1.2rem 2rem;
  background: var(--white-color);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10; /* Increased z-index to ensure navbar stays on top */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app__navbar-logo {
  display: flex;
  align-items: center;
}

.app__navbar-logo a,
.app__navbar-links li a {
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition for hover effects */
}

.app__navbar-logo-name {
  color: var(--accent-color);
  font-weight: var(--font-weight-black);
  font-size: 2rem;
  font-family: var(--font-base);
}

.app__navbar-logo-title {
  color: var(--accent-color);
  font-weight: var(--font-weight-thin);
  font-size: 1.25rem;
  font-family: var(--font-base);
}

.app__navbar-links {
  display: flex;
  align-items: center;
  list-style: none;
}

.app__navbar-links li {
  margin: 0 1rem;
}

.app__navbar-links li a {
  color: var(--accent-color);
  font-family: var(--font-base);
  font-weight: var(--font-weight-thin);
  font-size: 1.2rem;
  text-transform: uppercase;
}

.app__navbar-links li a:hover,
.app__navbar-menu div ul li a:hover {
  font-weight: var(--font-weight-medium);
}

/* Responsive styles for smaller screens */
@media screen and (max-width: 900px) {
  .app__navbar-links {
    display: none;
  }
  .app__navbar-menu {
    display: block; /* Show the menu icon/button for mobile */
  }
}

@media screen and (max-width: 800px) {
  .app__navbar {
    padding: 1.2rem;
  }

  .app__navbar-menu,
  .app__navbar-menu div {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-accent);
  position: relative; /* Adjusted for consistency */
}

/* Adjustments to mobile menu styling */
.app__navbar-menu div {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11; /* Ensure it's above the navbar */
  width: 80%;
  height: 100vh;
  padding: 1rem;
  background-color: var(--background-accent);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);
}

.app__navbar-links-active {
  font-weight: var(--font-weight-medium) !important;
}
