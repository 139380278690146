.modal {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items to the start */
  position: fixed;
  z-index: 1000; /* Ensure z-index is high enough */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto; /* Allows scrolling if modal content is longer than the viewport */
  padding-top: 60px; /* Adjust this value to your navbar's height */
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050; /* Ensure it's above other content */
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
  background-color: #fefefe;
  margin: auto;
  justify-content: center;
  width: auto;
  max-width: 100%;
  max-height: 90vh;
  border-radius: 1%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border: 1px solid #888;
}

.close {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  border: none;
  cursor: pointer;
}

.close:before {
  content: '×';
  font-size: 24px;
  color: #333;
}

.close:hover,
.close:focus {
  background-color: #e0e0e0;
  outline: none;
}

.modal-content img {
  max-width: 100%;
  max-height: 70vh;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .modal {
    padding-top: 0; /* Remove the padding-top introduced for large screens */
    align-items: center; /* This centers the modal vertically */
    justify-content: center; /* This centers the modal horizontally */
  }

  .modal-content {
    max-height: 80vh; /* Adjust this value as needed to fit in the viewport */
    width: 90%; /* Maintain the width adjustment for smaller screens */
    padding: 10px; /* Keep the padding adjustment for smaller screens */
  }

  .close {
    padding: 0;
  }
}
