.app__social {
  color: var(--black-color);
  font-family: var(--font-base);
  font-weight: var(--font-weight-medium);
  font-size: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app__social-links {
  display: flex;
  align-items: center;
}

.app__social-links-email-logo,
.app__social-links-linkedin-logo,
.app__social-links-leetcode-logo,
.app__social-links-github-logo {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
}

.app__social-links-email a,
.app__social-links-linkedin a,
.app__social-links-leetcode a,
.app__social-links-github a {
  text-decoration: none;
  color: inherit;
}
