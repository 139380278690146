.carousel-container {
  margin: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel-wrapper {
  position: relative;
  width: 100%;
  max-width: 50rem; /* Aligns with .app__about_me-container width */
  margin: auto;
  overflow: hidden;
}

.carousel-content-wrapper {
  position: relative;
  height: 35rem;
  width: 100%;
  max-width: 50rem; /* Aligns with the content width */
  margin: auto; /* Centers the carousel */
  overflow: hidden;
  border-radius: 2%;
}

.carousel-image-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-in-out;
  opacity: 0; /* Ensures images start invisible and fade in */
  border-radius: 2%;
  cursor: pointer;
}

.carousel-image-container.active {
  opacity: 1;
}

.carousel-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.carousel-content-wrapper:hover .expand-icon {
  opacity: 1;
  transform: scale(2);
}

.left-arrow:hover ~ .carousel-content-wrapper .expand-icon,
.right-arrow:hover ~ .carousel-content-wrapper .expand-icon {
  transform: none;
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  border-radius: 50%; /* Circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  transition:
    background-color 0.3s ease,
    transform 0.3s ease;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: rgba(255, 255, 255, 0.9); /* Lighter background on hover */
  color: #000; /* Change icon color */
  transform: translateY(-50%) scale(1.2); /* Slightly enlarge the arrows */
}

.carousel-dots {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: var(--accent-color); /* Default dot color */
  border-radius: 50%; /* Makes dots circular */
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: var(--tertiary-color); /* Active dot color */
}

.dot:hover {
  background-color: var(--tertiary-color);
}

@media (max-width: 800px) {
  .carousel-wrapper {
    width: 100vw;
    max-width: 90%;
  }

  .carousel-content-wrapper {
    height: 20rem;
    max-width: 100%;
  }

  .carousel-image-container {
    object-fit: cover;
    border-radius: 2%;
  }

  .left-arrow,
  .right-arrow {
    display: none;
  }
}
