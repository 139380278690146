.expand-icon {
  position: absolute;
  bottom: 1rem;
  right: 1.2rem;
  color: #fff;
  opacity: 0.75;
  z-index: 10;
  cursor: pointer;
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.expand-icon:hover {
  opacity: 1;
  transform: scale(2);
}

@media screen and (max-width: 800px) {
  .expand-icon {
    bottom: 1rem;
    right: 1rem;
  }

  .expand-icon:hover {
    transform: scale(1.5);
  }
}
