.app__utils-outlined_button,
.app__utils-full_button {
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 5px;
}

.app__utils-full_button {
  background-color: var(--accent-color);
  color: white;
}

.app__utils-outlined_button {
  background-color: transparent;
  color: var(--accent-color);
  border-color: var(--accent-color);
}

.app__utils-full_button:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}

.app__utils-outlined_button:hover {
  background-color: var(--accent-color);
  color: var(--white-color);
}
